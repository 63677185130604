/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React, { FC, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { ChevronDown, ChevronUp } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { System } from '@/components/icons';

import {
  checkPreset,
  DateInput,
  DateRange,
  DateRangeActions,
  DateRangeDisplay,
  DateRangePickerProps,
  getDateAdjustedForTimezone,
  PresetButton,
  PresetSelect,
  setPreset,
} from './calendar-helper';
import { usePresets } from './calendar-helper/presets';

/** The DateRangePicker component allows a user to select a range of dates */
const DateRangePicker: FC<DateRangePickerProps> = ({
  initialDateFrom = new Date(new Date().setHours(0, 0, 0, 0)),
  initialDateTo,
  initialCompareFrom,
  initialCompareTo,
  onUpdate,
  todaysDate,
  align = 'start',
  locale = 'en-US',
  onRangeChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const presets = usePresets();
  const searchParams = useSearchParams();
  const daysParam = searchParams.get('days');
  const { messages } = useLanguage();
  const langData = messages?.PromptFilters;

  // Get initial range based on URL parameter
  const getInitialRange = () => {
    const today = todaysDate ? todaysDate : new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 29); // -29 because today is included

    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 6); // -6 because today is included

    let from;
    let to;

    switch (daysParam) {
      case 'all':
        from = undefined;
        to = undefined;
        break;
      case '30':
        from = getDateAdjustedForTimezone(thirtyDaysAgo);
        to = getDateAdjustedForTimezone(today);
        break;
      case '7':
      default:
        from = getDateAdjustedForTimezone(sevenDaysAgo);
        to = getDateAdjustedForTimezone(today);
        break;
    }

    return { from, to };
  };

  const [range, setRange] = useState<DateRange>(getInitialRange());
  const [rangeCompare, setRangeCompare] = useState<DateRange | undefined>(
    initialCompareFrom
      ? {
          from: new Date(new Date(initialCompareFrom).setHours(0, 0, 0, 0)),
          to: initialCompareTo
            ? new Date(new Date(initialCompareTo).setHours(0, 0, 0, 0))
            : new Date(new Date(initialCompareFrom).setHours(0, 0, 0, 0)),
        }
      : undefined
  );
  // Refs to store the values of range and rangeCompare when the date picker is opened
  const openedRangeRef = useRef<DateRange | undefined>();
  const openedRangeCompareRef = useRef<DateRange | undefined>();

  const [selectedPreset, setSelectedPreset] = useState<string | undefined>(
    daysParam === 'all' ? 'allTime' : daysParam === '30' ? 'last30' : 'last7'
  );

  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== 'undefined' ? window.innerWidth < 960 : false
  );

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 960);
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update range when URL parameter changes
  useEffect(() => {
    if (daysParam) {
      const newRange = getInitialRange();
      setRange(newRange);
      setSelectedPreset(daysParam === 'all' ? 'allTime' : `last${daysParam}`);
    }
  }, [daysParam]);

  const resetValues = (): void => {
    if (openedRangeRef.current) {
      setRange(openedRangeRef.current);
    } else {
      setRange({
        from:
          typeof initialDateFrom === 'string'
            ? getDateAdjustedForTimezone(initialDateFrom)
            : initialDateFrom,
        to: initialDateTo
          ? typeof initialDateTo === 'string'
            ? getDateAdjustedForTimezone(initialDateTo)
            : initialDateTo
          : typeof initialDateFrom === 'string'
            ? getDateAdjustedForTimezone(initialDateFrom)
            : initialDateFrom,
      });
    }

    if (openedRangeCompareRef.current) {
      setRangeCompare(openedRangeCompareRef.current);
    } else {
      setRangeCompare(
        initialCompareFrom
          ? {
              from:
                typeof initialCompareFrom === 'string'
                  ? getDateAdjustedForTimezone(initialCompareFrom)
                  : initialCompareFrom,
              to: initialCompareTo
                ? typeof initialCompareTo === 'string'
                  ? getDateAdjustedForTimezone(initialCompareTo)
                  : initialCompareTo
                : typeof initialCompareFrom === 'string'
                  ? getDateAdjustedForTimezone(initialCompareFrom)
                  : initialCompareFrom,
            }
          : undefined
      );
    }
  };

  useEffect(() => {
    checkPreset(presets, range, setSelectedPreset);
    if (onRangeChange) {
      onRangeChange(range);
    }
  }, [range]);

  useEffect(() => {
    if (isOpen) {
      openedRangeRef.current = range;
      openedRangeCompareRef.current = rangeCompare;
    }
  }, [isOpen]);

  const isAllTimeSelected = selectedPreset === 'allTime';

  const handlePresetChange = (presetName: string) => {
    setSelectedPreset(presetName);
    if (presetName === 'allTime') {
      setRange({ from: undefined, to: undefined });
    } else {
      setPreset(presetName, presets, setRange, rangeCompare, setRangeCompare);
    }
  };

  const handleCalendarSelect = (
    value: { from?: Date; to?: Date } | undefined
  ) => {
    if (value?.from) {
      setSelectedPreset(undefined);
      if (!value.to) {
        setRange({ from: value.from, to: value.from });
      } else {
        setRange({ from: value.from, to: value.to });
      }
    }
  };

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open: boolean) => {
        if (!open) {
          resetValues();
        }
        setIsOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={`w-full justify-start rounded-sm border border-form-control-border-rest px-3 ${
            isAllTimeSelected ? 'py-2' : 'py-8'
          } hover:bg-transparent`}
        >
          <div className="flex w-full items-start justify-between">
            <DateRangeDisplay
              range={range}
              rangeCompare={rangeCompare}
              locale={locale}
              selectedPreset={selectedPreset}
            />
            <div className="mr-1 scale-125 text-body-secondary">
              {isOpen ? (
                <ChevronUp size={18} strokeWidth={3} />
              ) : (
                <ChevronDown size={18} strokeWidth={3} />
              )}
            </div>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        sideOffset={2}
        align={align}
        className="w-auto rounded-lg border border-line-secondary bg-card-01 px-[30px] py-6 shadow-lg"
      >
        <div className="ml-1 flex">
          {!isAllTimeSelected ? (
            <div className="flex items-center gap-2">
              <DateInput
                value={range.from}
                onChange={(date) => {
                  setSelectedPreset(undefined);
                  const toDate =
                    range.to == null || date > range.to ? date : range.to;
                  setRange((prevRange) => ({
                    ...prevRange,
                    from: date,
                    to: toDate,
                  }));
                }}
              />
              <div className="py-1">-</div>
              <DateInput
                value={range.to}
                onChange={(date) => {
                  setSelectedPreset(undefined);
                  const fromDate =
                    range.from && date < range.from ? date : range.from ?? date;
                  setRange((prevRange) => ({
                    ...prevRange,
                    from: fromDate,
                    to: date,
                  }));
                }}
              />
            </div>
          ) : (
            <div className="ml-3 flex items-center gap-1">
              {System.InfoIcon('h-[18px] w-[18px] text-form-input-disabled')}{' '}
              <span className="text-[14px] font-normal leading-[19.6px] text-body-subtle">
                {langData?.date_range.all_time_info_msg}
              </span>
            </div>
          )}
        </div>

        <div className="flex">
          {isSmallScreen && (
            <PresetSelect
              presets={presets}
              selectedPreset={selectedPreset}
              onPresetChange={handlePresetChange}
            />
          )}
          <div className="flex">
            <Calendar
              mode="range"
              onSelect={handleCalendarSelect}
              selected={range}
              numberOfMonths={isSmallScreen ? 1 : 2}
              defaultMonth={
                new Date(
                  new Date().setMonth(
                    new Date().getMonth() - (isSmallScreen ? 0 : 1)
                  )
                )
              }
            />
            <Separator
              orientation="vertical"
              className="mx-4 h-full bg-white/[0.08]"
            />
          </div>
          {!isSmallScreen && (
            <div className="flex w-full flex-col items-start gap-1">
              {presets.map((preset) => (
                <PresetButton
                  key={preset.name}
                  label={preset.label}
                  isSelected={selectedPreset === preset.name}
                  onClick={() => handlePresetChange(preset.name)}
                />
              ))}
            </div>
          )}
        </div>
        <DateRangeActions
          setIsOpen={setIsOpen}
          range={range}
          rangeCompare={rangeCompare}
          openedRangeRef={openedRangeRef}
          openedRangeCompareRef={openedRangeCompareRef}
          onUpdate={(updatedRange) => {
            if (onUpdate) {
              onUpdate(updatedRange);
            }
          }}
          resetValues={resetValues}
        />
      </PopoverContent>
    </Popover>
  );
};

export { DateRangePicker };

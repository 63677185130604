/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React, { useEffect, useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { SpanDetailsProps } from 'types/traces';

import BasicLoader from '@/components/spinner/basic-loader';

import { DataModel, GanttChart } from './gantt-chart';
import SpanDetailsDialog from './span-details/span-detail-dialog';

const SpanDetails: React.FC<SpanDetailsProps> = ({
  spanDetails,
  traceSpanLoading,
  showErrorsOnly,
  showDataOnly,
  selectedComponents,
  readOnly,
}) => {
  const { messages } = useLanguage();
  const [openSpanDetail, setOpenSpanDetail] = useState(false);
  const [spanModalData, setSpanModalData] = useState({});
  const [data, setData] = useState<DataModel[]>([]);

  // Component type mapping
  const componentTypeMap: { [key: string]: string } = {
    models: 'model.',
    inference: 'inference.',
    app_hosting: 'app_hosting.',
    vectordb: 'vectorstore.',
    agent: 'agent.',
    workflow: 'workflow.',
  };

  // Helper function to determine if a span meets the filter criteria
  const checkSpanMeetsCriteria = (span: any): boolean => {
    // Component filter
    if (selectedComponents && selectedComponents.length > 0) {
      const entities = span.attributes?.entity || [];
      const matchesComponent = entities.some((entity: any) => {
        return selectedComponents.some((component) => {
          const typePrefix = componentTypeMap[component];
          return entity.type?.startsWith(typePrefix);
        });
      });

      if (!matchesComponent) return false;
    }

    // Error and data filters
    const isErrorSpan = span.status?.code === 'error';
    if (showDataOnly && showErrorsOnly) {
      // Both filters active: must have data events AND error status
      return (
        span.events?.some((event: any) => event.name.startsWith('data.')) &&
        isErrorSpan
      );
    } else if (showDataOnly) {
      // Only data filter: must have data events
      return span.events?.some((event: any) => event.name.startsWith('data.'));
    } else if (showErrorsOnly) {
      // Only error filter: must have error status
      return isErrorSpan;
    }
    return true; // No filters active: all spans meet criteria
  };

  // Converts raw span data to the format needed by GanttChart
  const convertSpansToDataModel = (spans: any[]): DataModel[] => {
    return spans.map((span, index) => ({
      id: span.span_id,
      startTime: span.start_time,
      endTime: span.end_time,
      duration: span.duration_ms,
      label:
        span.span_name ||
        `${messages?.Traces?.trace_span_details?.span} ${index + 1}`,
      description: `${messages?.General?.duration}: ${span.duration_ms}${messages?.Traces?.trace_details?.milli_seconds}`,
      parentId:
        span.parent_id && span.parent_id.toLowerCase() !== 'none'
          ? span.parent_id
          : undefined,
      children: [],
      color: span.status?.code === 'error' ? '#F26C74' : '#32BCAF', // Set color based on error status
      isFiltered: !checkSpanMeetsCriteria(span), // Marks spans that don't meet criteria
    }));
  };

  // Click handler for spans
  const handleSpanClicked = (id: string) => {
    // Only handle clicks on non-filtered spans
    const selectedSpan = data.find((span) => span.id === id);
    if (selectedSpan && !selectedSpan.isFiltered) {
      const originalSpan = spanDetails?.spans?.find(
        (span) => span.span_id === id
      );
      setSpanModalData(originalSpan);
      setOpenSpanDetail(true);
    }
  };

  useEffect(() => {
    setData(
      spanDetails?.spans ? convertSpansToDataModel(spanDetails.spans) : []
    );
  }, [spanDetails?.spans, showErrorsOnly, showDataOnly, selectedComponents]);

  return (
    <>
      {traceSpanLoading ? (
        <div className="mt-2 flex h-[414px] flex-1 items-center justify-center border border-[#0A343C]">
          <BasicLoader />
        </div>
      ) : (
        <div className="mt-3">
          {readOnly ? (
            <GanttChart data={data} />
          ) : (
            <GanttChart data={data} onClick={handleSpanClicked} />
          )}

          {openSpanDetail && (
            <SpanDetailsDialog
              isOpen={openSpanDetail}
              onOpenChange={setOpenSpanDetail}
              spanData={spanModalData}
            />
          )}
        </div>
      )}
    </>
  );
};

export { SpanDetails };

/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';
import { formatDateForDateRangeDisplay } from '@/utils/formatedDates';

import { usePresets } from './presets';
import { DateRange } from './types';

interface DateRangeDisplayProps {
  range: DateRange;
  rangeCompare?: DateRange;
  locale: string;
  selectedPreset?: string;
}

export const DateRangeDisplay: React.FC<DateRangeDisplayProps> = ({
  range,
  rangeCompare,
  locale,
  selectedPreset,
}) => {
  const { messages } = useLanguage();
  const langData = messages?.PromptFilters;
  const presets = usePresets();
  const preset = presets.find((p) => p.name === selectedPreset);

  const getDateRangeInfo = () => {
    if (selectedPreset) {
      const preset = presets.find((p) => p.name === selectedPreset);
      return preset ? preset.label : '';
    }

    if (range.to && range.from) {
      const diffTime = Math.abs(range.to.getTime() - range.from.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      return `${diffDays} ${langData?.date_range.day}${
        diffDays !== 1 ? `${messages?.General?.s}` : ''
      }`;
    }

    return `${langData?.date_range.range_warning}`;
  };

  return (
    <div className="flex flex-col items-start">
      {preset?.name === 'allTime' ? (
        <div className="text-form-input-text font-normal text-form-input-rest">
          {langData?.date_range.date_range_placeholder}
        </div>
      ) : (
        <div className="text-form-input-text font-normal text-form-input-rest">
          {getDateRangeInfo()}
        </div>
      )}
      {/* Only show date range if not "all" */}
      {preset?.name !== 'allTime' && (
        <div className="pt-1 text-form-input-text text-form-input-disabled ">
          {range.from && formatDateForDateRangeDisplay(range.from, locale)}
          {range.to && ` - ${formatDateForDateRangeDisplay(range.to, locale)}`}
        </div>
      )}
      {preset?.name !== 'allTime' && rangeCompare && (
        <div className="-mt-1 text-xs opacity-60">
          {langData?.date_range.vs}.{' '}
          {rangeCompare.from &&
            formatDateForDateRangeDisplay(rangeCompare.from, locale)}
          {rangeCompare.to &&
            ` - ${formatDateForDateRangeDisplay(rangeCompare.to, locale)}`}
        </div>
      )}
    </div>
  );
};

/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
import { format } from 'date-fns';

export const formatDateForDateRangeDisplay = (date: Date, locale: any) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString(locale, { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const formatDateMonthTime = (dateString: string) => {
  if (dateString) {
    const date = new Date(dateString);
    return format(date, 'd MMM h:mma');
  } else {
    return;
  }
};

import { usePathname } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';

import { DateRange, Preset } from './types';

// Define hook to return localized presets
export const usePresets = (): Preset[] => {
  const { messages } = useLanguage();
  const langData = messages?.PromptFilters?.date_range;
  const pathname = usePathname();

  const presets: Preset[] = [
    { name: 'today', label: langData?.today || 'Today' },
    { name: 'yesterday', label: langData?.yesterday || 'Yesterday' },
    { name: 'last7', label: langData?.last7 || 'Last 7 days' },
    { name: 'last14', label: langData?.last14 || 'Last 14 days' },
    { name: 'last30', label: langData?.last30 || 'Last one month' },
    // TODO take up the custom label later
    // { name: 'custom', label:  'Custom' },
  ];

  // Add "allTime" preset only if on the /traces page
  if (pathname.includes('/traces')) {
    presets.push({ name: 'allTime', label: langData?.allTime || 'All time' });
  }

  return presets;
};

// Refactor getPresetRange to accept the presets as an argument
export const getPresetRange = (
  presetName: string,
  presets: Preset[]
): DateRange => {
  const preset = presets.find(({ name }) => name === presetName);
  if (!preset) throw new Error(`Unknown date range preset: ${presetName}`);

  const from = new Date();
  const to = new Date();

  switch (preset.name) {
    case 'today':
      from.setHours(23, 59, 59, 999);
      to.setDate(to.getDate());
      to.setHours(23, 59, 59, 999);
      break;
    case 'yesterday':
      from.setDate(from.getDate() - 1);
      to.setDate(to.getDate() - 1);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'last7':
      from.setDate(from.getDate() - 6);
      to.setHours(23, 59, 59, 999);
      break;
    case 'last14':
      from.setDate(from.getDate() - 13);
      break;
    case 'last30':
      from.setDate(from.getDate() - 29);
      to.setHours(23, 59, 59, 999);
      break;
    case 'allTime':
      // Return null dates to indicate "All time"
      return { from: undefined, to: undefined };
    // case 'custom'
  }

  return { from, to };
};
